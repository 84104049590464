import { Text, Flex, FlexItem, Dropdown, Tooltip, ChatIcon } from '@fluentui/react-northstar';
import { app } from '@microsoft/teams-js';

// Services
import { useTranslation } from 'SERVICES/i18n';

// Constants
import {
  ENTER_KEY_CODE,
  LANGUAGE_PREFIX,
  TEAMS_LANGUAGE_RESOURCE,
  TEAMS_MEETING_LINK_PARAMS,
} from 'CONSTANTS/teamsConstants';

// Utils
import { ILanguageDropdown } from 'UTILS/teamsInterface';

// Styles
import './LanguageSelectionDropdown.scss';
import AkouoSupport from 'COMPONENTS/AkouoSupport';

const LanguageSelectionDropdown = ({
  interpreterMeetings,
  handleLanguageSelection,
  selectedLanguage,
  teamsMeetingDetail,
  ccOn,
  setCcOn,
  apiFetchComplete,
}: ILanguageDropdown) => {
  const { t } = useTranslation(TEAMS_LANGUAGE_RESOURCE, LANGUAGE_PREFIX.INMEETING);

  /* 
  // creates in meeting dialogue
  const createInMeetingDialogue = async (cardContentObject: ICardContentObject) => {
    const adaptiveCardContent = {
      type: ADAPTIVE_CARD_SCHEMA.CARD_TYPE,
      body: [
        {
          type: ADAPTIVE_CARD_SCHEMA.BODY_TYPE,
          text: t(cardContentObject.cardBodyContentKey),
          wrap: true,
        },
      ],
      actions: [
        {
          type: ADAPTIVE_CARD_SCHEMA.ACTION_SUBMIT,
          title: t(cardContentObject.cardActionKey),
          style: ADAPTIVE_CARD_SCHEMA.CARD_STYLE,
        },
      ],
      $schema: ADAPTIVE_CARD_SCHEMA.CARD_SCHEMA,
      version: ADAPTIVE_CARD_SCHEMA.VERSION,
    };

    const adaptiveCardDialogInfo: AdaptiveCardDialogInfo = {
      title: t(cardContentObject.cardTitleKey) as string,
      card: JSON.stringify(adaptiveCardContent),
      size: {
        height: DialogDimension.Small,
        width: DialogDimension.Medium,
      },
    };

    dialog.adaptiveCard.open(adaptiveCardDialogInfo, () => {
      return;
    });
  };
  */

  const chatOnClick = async () => {
    // app.openLink() method redirects to conversation of current meeting.
    const chatLink = teamsMeetingDetail.joinUrl.replace(
      TEAMS_MEETING_LINK_PARAMS.JOIN_MEETING,
      TEAMS_MEETING_LINK_PARAMS.CHAT,
    );
    app.openLink(chatLink);
    // const cardContentObject: ICardContentObject = {
    //   cardTitleKey: 'CHAT_NAVIGATION',
    //   cardBodyContentKey: 'CHAT_NAVIGATION_DESCRIPTION',
    //   cardActionKey: 'CLOSE_BUTTON_TEXT',
    // };
    // await createInMeetingDialogue(cardContentObject);
  };

  const languageDropdown = interpreterMeetings.map((lang: any) => {
    const obj = {
      ...lang,
      header: lang.label, // for display
      onClick: () => handleLanguageSelection(lang),
    };
    return obj;
  });

  if (apiFetchComplete && interpreterMeetings.length === 1) {
    return (
      <Text>
        <Flex column>
          <span>{t('NO_LANGUAGES_CONFIGURED_PARTICIPANT')}</span>
          <AkouoSupport className="pt-1" />
        </Flex>
      </Text>
    );
  }
  return (
    <Flex column className="height30">
      <FlexItem>
        <Flex gap="gap.large">
          <Text
            className="bottom-padding"
            align="start"
            size="medium"
            // width="50%"
            content={t('SIDE_PANEL_MUST_REMAIN_OPEN')}
          />
          <Tooltip
            content={t('TOOLTIP_MSG')}
            trigger={
              <div
                className="cursor-pointer chat-border"
                role="button"
                tabIndex={0}
                onClick={() => chatOnClick()}
                onKeyDown={(e) => {
                  if (e.keyCode === ENTER_KEY_CODE) {
                    e.preventDefault();
                    chatOnClick();
                  }
                }}
              >
                <ChatIcon outline size="large" />
                <Text size="small" content={t('CHAT_BTN')} />
              </div>
            }
          />
        </Flex>
      </FlexItem>
      <Text
        className="bottomPadding"
        align="center"
        size="smaller"
        content={t('INTERPRETERS_CONNECTED_WHEN_AI_IS_PARTICIPANT')}
      />
      <Flex>
        <Dropdown
          items={languageDropdown}
          id="languageSelectionDropdown"
          placeholder={`${t('DROPDOWN_PLACEHOLDER')}`}
          fluid
          value={selectedLanguage ? selectedLanguage?.label : t('DROPDOWN_PLACEHOLDER')}
        />
        {teamsMeetingDetail?.captionEvents?.length > 0 && (
          <Flex
            className={`lang-btn cc-padding ${!selectedLanguage?.label && 'disabled-cc'} ${
              ccOn && 'selected-lang-btn'
            }`}
            onClick={() => {
              setCcOn(!ccOn);
            }}
          >
            <Text>CC</Text>
          </Flex>
        )}
      </Flex>
    </Flex>
  );
};

export default LanguageSelectionDropdown;
